/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  background-color: #060a12 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.navbar-logo {
  width: 100%;
  height: auto;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/************ Banner Css ************/
.banner .col {
  width: 50%;
  display: inline-block;
  padding-top: 100px;
}

.hero-container {
  border-bottom: #fff solid 1px;
  opacity: 0.9;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-size: cover;
}

.banner {
  margin-top: 0;
  background-color: #060a12 !important;
  background-position: top center;
  position: static;
  background-size: cover;
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

@keyframes cursor {
  50% {
    border-color: transparent
  }
}

@keyframes typing {
  from {
    width: 0%
  }
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.banner img {
  width: 80%;
  height: auto;
  border-radius: 50%;
  border: #030129 7px solid;
  animation: updown 3s linear infinite;
}

.banner {
  padding: 20px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.banner h1 {
  font-size: 40px;
}

@keyframes updown {
  0% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes lefttoright {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateY(0px);
  }
}


.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

/*About CSS*/


.about-container {
  padding: 40px;
}

.about-me-title {
  padding: 30px;
  font-family: Impact, 'Arial Narrow Bold', sans-serif;
  font: bold;
  text-align: center;
  font-size: 50px;
}

.controls {
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: row;
  border-bottom: #545353 solid 2px;
}

.control-button {
  padding-bottom: 10px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.control-button:hover {
  color: #969696;
}

.active-button {
  color: #969696;

}

.about-info {
  border-radius: 20px;
  margin: 20px;
  border: #545353 solid 2px;
}

.about-me-text {
  padding: 20px;
  font-size: 23px;
  color: #ffffff;
  width: 50%;
}

.about-me-img {
  display: block;
  width: 50%;
  border-radius: 50px;
  margin: 20px;
  max-height: 500px
}

.about-me-description {
  padding: 20px;
  font-size: 25px;
  color: #ffffff;
  text-align: center;
}

.text-animation {
  animation: lefttoright 5s lin;
}

.about-me {
  min-height: 80vh;
  padding: 0 50px 0 50px;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
}

.slide {
  padding: 50px;
}

.carousel-div-about {
  background-size: cover;


}

.carousel-div-about {
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-inner {
  max-width: 1536px;
  max-height: 700px;
}








/*Projects CSS*/
* {
  margin: 0px;
}

.container-title {
  font-family: Impact, 'Arial Narrow Bold', sans-serif;
  font: bold;
}

.align-the-title {
  display: flex;
  flex-direction: column;
}

.Project-Container {
  cursor: grab;
  padding-bottom: 50px;
  background: transparent;
  width: 100vw;
  max-width: 1536px;
  display: flex;
  flex-direction: row;
  width: 80vw;
  align-items: center;
}

.center-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.carousel {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: transparent;
}

.project-holder-active {
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-image: url('./assets/img/banner-bg.png');
  background-color: #545353;
  z-index: 190;
  width: 25vw;
  border-radius: 10px;
  margin: 10px;
  border: 2px solid rgba(255, 255, 255, 0);
}

.project-holder {
  display: none;
}

.project-holder-active:hover {
  border: #ffffff 2px solid;
  box-shadow: 0 0 10px #ffffff;
}

.carousel {
  border-radius: 40px;
  margin-top: 20px;
  margin-bottom: 50px;
}

.Project-Description {
  color: white;
  justify-content: center;
  padding: 20px;
}

.Project-Title {
  padding-top: 15px;
  color: white;
  text-align: center;
}

.Project-Title:hover {
  color: rgb(0, 119, 255);
  transition: 0.4s;
}

.project-image {
  display: block;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
}

.item img {
  pointer-events: none;
}

.arrow-and-project-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.project-button {
  text-decoration: none;
}

/*arrow css*/
.arrow {
  border: solid rgb(255, 255, 255);
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 1vw;
  margin: 1vw;
}

.arrow:hover {
  border: solid #7d7d7d;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 1vw;
  margin: 1vw;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.card-info {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.3px;
  width: 96%;
  opacity: 0.8;
}

/*Footer CSS*/
.footer-container {
  border-top: #ffffff 2px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding-top: 20px;
  width: 100%;
  height: 100%;
}

.footer-container a {
  text-decoration: none;
  color: rgb(0, 129, 243);
}

/*Page not found CSS*/


.page-not-found-h3 {
  font-size: 100px;
}

.page-not-found-h2 {
  font-size: 60px;
}

.purple-digit {
  color: #6f00ff;
}

/*Login CSS*/
.login-with-google-btn {
  background-color: wheat;
  height: 100px;
  width: 200px;
}

.loginPage {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* Create post */
.createPostPage {
  padding-top: 120px;
  width: 100%;
  min-height: calc(100vh - 80px);
  display: grid;
  place-items: center;
}

.cpContainer {
  width: 500px;
  height: auto;
  padding: 20px;
  background-color: black;
  border-radius: 12px;
  color: white;
  display: flex;
  flex-direction: column;
}

.cpContainer h1 {
  text-align: center;
}

.cpContainer label {
  font-size: 25px;
}

.cpContainer .inputGp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.inputGp input,
.inputGp textarea {
  font-size: 18px;
  border: none;
  border-radius: 2px;
  padding: 5px;
}

.inputGp input {
  height: 40px;
}

.inputGp textarea {
  height: 150px;
}

.cpContainer button {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
}

input,
textarea {
  margin-top: 5px;
}

.submitPostButton {
  background-color: white;
}

/*Post page CSS*/
.postPage {
  min-height: calc(100vh - 80px);
  padding-top: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.post {
  padding: 20px;
  margin: 20px;
  background-color: #2a2b36;
  width: 800px;
  max-height: 300px;
  border-radius: 30px;
}

.blog-title-div {
  text-align: center;
}

.author-div {
  font-size: 15px;
  opacity: 0.8;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
}

.postText {
  text-align: justify;
  padding: 20px;
}

.text-description {
  max-height: 158px;
  overflow-y: hidden;
  border-bottom: #969696 solid 2px;
  font-size: 17px;
}

.author-name-div {
  width: 50%;
}

.post-date-div {
  width: 50%;
  text-align: right;
}

.go_to_arrow {
  text-decoration: none !important;
  color: #ffffff;
  padding-top: 10px;
  height: 30px;
  width: 30px;
}

.go_to_arrow:hover {
  color: #863ae9;
  transition: 0.4s;
}

.arrow-link {
  height: 30px !important;
  width: 30px !important;
}

/* article css*/


.main-article {
  padding-top: 140px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 80px);
}

.main-article-title {
  padding-top: 20px;
}

.blog-page-article {
  padding: 15px;
  max-width: 1000px;
  overflow: hidden;
  background-color: #2a2b36;
}

.author-blog-div {
  width: 50%;
}

.date-div {
  text-align: right;
  width: 50%;
}

.blog-text-content {
  max-width: 100%;
  text-align: left;
}

.blog-content {
  padding: 20px;
}

/*Responsive CSS*/
@media screen and (max-width: 800px) {
  .post {
    width: 400px;
  }

  .container-title {
    text-align: center;
  }

  .card-info {
    display: none;
  }

  .Project-Container {
    display: flex;
    flex-direction: column;
  }

  .project-holder-active {
    width: 80vw;
  }

  .left {
    display: none;
  }

  .right {
    display: none;
  }

  .image-of-me {
    position: hidden;
    display: none !important;
  }

  .controls {
    display: flex;
    flex-direction: column;
  }

  .about-me {
    padding: 0 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .about-me-img {
    width: 100%;
    border: none;
    margin: 0px;
    padding: 0px;

  }

  .about-me-text {
    font-size: 13px;
    width: 100%;
    width: 100%;
  }

  .card-info {
    padding: 20px;
  }

  .about-me {
    padding: 0px;
  }

  footer p {
    font-size: 10px;
    line-height: 0px;
  }

  footer h1 {
    font-size: 30px;
  }

  .carousel {
    overflow: visible;
    width: 100vw;
  }

  .control-button {
    font-size: 30px;
  }

  .banner .col {
    width: 50%;
    display: inline-block;
    padding-top: 115px;
  }

  .nav-bar-min {
    background-color: #060a12;
  }

  .banner h1 {
    font-size: 24px;
  }

  .banner p {
    font-size: 15px;
  }

  .page-not-found-h2 {
    font-size: 35px;
  }

  .navbar-logo {
    transform: translateX(23px);
  }
}

@media screen and (max-width: 400px) {
  .post {
    width: 250px;
  }


  .postText {
    font-size: 14px;
  }

  .author-div p {
    font-size: 10px;
  }
}

.mwidth {
  max-width: 1536px;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}